<script setup lang="ts">
const tabs = [
  { label: 'Buttons', to: '/component-library/button' },
  { label: 'Inputs date', to: '/component-library/input-date' },
  { label: 'Charts', to: '/component-library/charts' },
];
</script>
<template>
  <ui-page :title="$t('component-library.title')" :tabs="tabs"></ui-page>
</template>
